<template>
  <div>
    <b-table
      head-variant="light"
      :fields="fields"
      :items="items"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      stacked="md"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="ensalar(item.item)">
            Alterar turma
          </b-dropdown-item>
          <b-dropdown-item
            @click="desenturmar(item.item)"
            v-if="!alunosSemTurma"
          >
            Remover da turma
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import TurmaService from '@/common/services/academico/turma.service';

export default {
  props: {
    items: { type: Array, default: Array },
    alunosSemTurma: { type: Boolean, default: false },
  },
  data() {
    return {
      fields: [
        {
          key: 'nome',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'codigoContrato',
          label: 'Matrícula',
          sortable: true,
        },
        {
          key: 'codigoMatricula',
          label: 'Contrato',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    ensalar(item) {
      this.$emit('ensalar', 'ensalamento', item);
    },
    desenturmar(item) {
      this.$store.dispatch(START_LOADING);
      TurmaService.desenturmarAluno(item)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('ACADEMICO.GERENCIADOR_TURMAS_ALUNO_REMOVIDO')
          );
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
