<template>
  <div class="row">
    <div class="col-12 col-md-2 col-lg-2">
      <input-select-search
        :label="$t(`ACADEMICO.ANO_LETIVO`)"
        :options="opcoes.anosLetivos"
        v-model="filtro.anoLetivoId"
        required
      />
    </div>
    <div class="col-12 col-md-3">
      <input-select-search
        :label="$t(`ACADEMICO.CURSO`)"
        :options="opcoes.cursos"
        v-model="filtro.cursoId"
        :isDisabled="desabilitar.curso"
        required
      />
    </div>
    <div class="col-12 col-md-3">
      <input-select-search
        ref="grupoTurmaId"
        :label="$t(`ACADEMICO.GRUPO_TURMA`)"
        :options="opcoes.grupoTurmas"
        v-model="filtro.grupoTurmaId"
        :isDisabled="desabilitar.grupoTurma"
        required
      />
    </div>
    <div class="col-12 col-md-auto align-items-center">
      <b-button
        variant="primary"
        class="botao-acao-filtro mt-3 w-100"
        @click="filtrar"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </div>
    <div class="col-12 col-md-auto">
      <b-button
        variant="secondary"
        class="botao-acao-filtro mt-3 w-100"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import GrupoTurmaService from '@/common/services/grupoTurma/grupo-turma.service';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    refiltrar: { type: Boolean, default: false },
  },
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      filtro: {
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
      },
      desabilitar: {
        grupoTurma: true,
        curso: true,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        grupoTurmas: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivo();
    this.$emit('refs', this.$refs);
  },
  watch: {
    'filtro.anoLetivoId'(anoLetivoId) {
      if (anoLetivoId) {
        this.getCursos(anoLetivoId);
      } else {
        this.opcoes.cursos = [];
        this.opcoes.grupoTurmas = [];
        this.desabilitar.curso = true;
        this.desabilitar.grupoTurma = true;
      }
      this.filtro.cursoId = null;
      this.filtro.grupoTurmaId = null;
    },
    'filtro.cursoId'(cursoId) {
      if (cursoId) {
        this.getGrupoTurmas();
      } else {
        this.desabilitar.grupoTurma = true;
        this.opcoes.grupoTurmas = [];
      }
      this.filtro.grupoTurmaId = null;
    },
    refiltrar: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          data.forEach((element) => {
            element.text = element.ano;
            element.value = element.id;
          });
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGrupoTurmas() {
      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
        this.filtro.cursoId,
        this.filtro.anoLetivoId
      )
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurmas = data;
          this.desabilitar.grupoTurma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    // FUNÇÕES FILTRO:
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },
    filtrar() {
      if (!this.validarFiltro()) return;
      this.$store.dispatch(START_LOADING);
      TurmaService.listarEnsalamento(this.filtro)
        .then(({ data }) => {
          data.anoLetivoId = this.filtro.anoLetivoId;
          data.grupoTurmaId = this.filtro.grupoTurmaId
          this.$emit('resultado', JSON.parse(JSON.stringify(data)));
        })
        .catch((err) => {
          mensagem.showErrors(err);

          this.$emit('resultado', []);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limparFiltro() {
      this.filtro = {};
      for (var ref in this.$refs) {
        this.$refs[ref].clear();
      }
      this.$emit('limparFiltro');
    },
  },
};
</script>
