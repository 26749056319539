<template>
  <modal
    id="modalEnsalamento"
    :exibir="exibir"
    :titulo="'Ensalamento'"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <ensalamento-form :form="form" @refs="getRefs" @ensalar="novoEnsalamento" :filtros="filtros" />

    <template v-slot:modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import TurmaService from '@/common/services/academico/turma.service';

// Components:
import Modal from '@/components/modal/Modal';
import EnsalamentoForm from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
    filtros: { type: Object, default: Object },
  },
  components: {
    Modal,
    EnsalamentoForm,
  },
  data() {
    return {
      refs: [],
      ensalamento: {},
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES CRUD:
    novoEnsalamento(formEnsalamento) {
      this.ensalamento = formEnsalamento;
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      TurmaService.enturmarAluno(this.ensalamento)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Aluno enturmado!');
          this.$emit('atualiza-tabela');
          this.fecharModal()
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
