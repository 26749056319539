<template>
  <div class="row">
    <div class="col-12">
      <label>Mover o aluno {{ form.nome }} para a turma:</label>
      <input-select-search
        ref="turmaId"
        v-model="turmaId"
        :options="opcoes.turmas"
        required
      />
      
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import TurmaService from '@/common/services/academico/turma.service';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    filtros: { type: Object, default: Object },
  },
  components: {
    InputSelectSearch,
  },
  mounted() {
    this.getTurmas();
    this.$emit('refs', this.$refs);
  },
  data() {
    return {
      turmaId: '',
      opcoes: {
        turmas: [],
      },
    };
  },
  watch: {
    turmaId() {
      this.form.turmaId = this.turmaId;
      this.$emit('ensalar', this.form);
    },
  },
  methods: {
    getTurmas() {
      this.$store.dispatch(START_LOADING);
      TurmaService.buscarPorGrupoTurmaIdAnoLetivoId(this.filtros)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.turmas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
