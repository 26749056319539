<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO.GERENCIADOR_TURMAS')"
      :subtitulo="$t('ACADEMICO.GERENCIADOR_TURMAS_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    />

    <!-- filtro -->
    <filtro
      :refiltrar="filtro.refiltrar"
      @resultado="getFiltro"
      @limparFiltro="limparFiltro"
    />

    <!-- resultado da busca -->
    <div class="accordion mt-5" role="tablist">
      <!-- alunos sem turma -->
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-AlunosSemTurma variant="info">
            Alunos sem turma
          </b-button>
        </b-card-header>
        <b-collapse
          id="accordion-AlunosSemTurma"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <tabela-turmas
              :items="itemsFiltro.alunosNaoEnturmados"
              @ensalar="openModal"
              :alunosSemTurma="true"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
      <!-- turmas -->
      <b-card
        v-for="resultado in itemsFiltro.turmas"
        :key="resultado.turmaId"
        no-body
        class="mb-1"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle="`accordion-` + resultado.turmaId"
            variant="info"
          >
            {{ resultado.turmaDescricao }}
          </b-button>
        </b-card-header>
        <b-collapse
          :id="`accordion-` + resultado.turmaId"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <tabela-turmas
              :items="resultado.alunosEnturmados"
              @ensalar="openModal"
              @atualiza-tabela="atualizarTabela"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>    <!-- modal -->
    <modal-ensalamento
      :exibir="modais.ensalamento"
      :form="modais.ensalamentoDados"
      :filtros="itemsFiltro"
      @atualiza-tabela="atualizarTabela"
      @fechar="closeModal('ensalamento')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Components:
import Filtro from './components/Filtro.vue';
import TabelaTurmas from './components/TabelaTurmas.vue';
import ModalEnsalamento from './components/Modal.vue';

export default {
  components: {
    Topbar,
    Filtro,
    TabelaTurmas,
    ModalEnsalamento,
  },
  data() {
    return {
      filtro: {
        refiltrar: false,
      },
      itemsFiltro:{},
      modais: {
        ensalamento: false,
        ensalamentoDados: {},
      },
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('ACADEMICO.GERENCIADOR_TURMAS') },
      ],
    };
  },
  methods: {
    // FUNÇÕES FILTRO:
    getFiltro(resultadoFiltro) {
      return (this.itemsFiltro = resultadoFiltro);
    },
    limparFiltro() {
      this.itemsFiltro = [];
    },
    atualizarTabela() {
      this.filtro.refiltrar == false
        ? (this.filtro.refiltrar = true)
        : (this.filtro.refiltrar = false);
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = {});
    },
    closeModal(modal) {
      return (this.modais[modal] = false);
    },
  },
};
</script>
